import { API } from '@/inc/api';
import { bus } from '@/main';
import { _st } from '@/softech';

export const item = {
    namespaced: true,
    state: {
        status      : []
    },
    mutations: {
        setStatus( state, payload ) {
            state.status = payload;
        }
    },
    actions: {
        async loadStatus({ commit, state }) {
            try {
                if( state.status.length > 0 )
                    return Promise.resolve();

                let api = new API();
                let res = await api.get('/item-status');

                if( !res.data.status ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                commit('setStatus', res.data.data);
                return Promise.resolve();
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async drop(_, { barcode, orderId, stationId }) {
            try {
                if( _st.isNUE( barcode ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid barcode is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }
                if( _st.isNUE( orderId ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid order number is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }
                if( _st.isNUE( stationId ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid station is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/item-drop', {
                    barcode,
                    stationId,
                    orderId,
                });

                if( res.data.status !== true ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                // remove item from order assigned items
                
                return Promise.resolve(barcode);
            }
            catch(error) {
                return Promise.reject(error);
            }
        }
    },
    getters: {
        status      : (state) => state.status
    }
}