import { API } from '@/inc/api';
import { _st } from '@/softech';
import { bus } from '@/main';

export const checkIn = {
    namespaced: true,
    state: {
        item        : null,
        missingItems: [],

        stationId   : 8,
    },
    mutations: {
        setItem( state, payload ) {
            state.item = payload;
        },
        setMissingItems( state, payload ) {
            state.missingItems = payload;
        },
    },
    actions: {
        async loadMissingItems({ commit, state }) {
            try {
                if( state.missingItems.length > 0 )
                    return;

                let api = new API();
                let res = await api.get('/missing-items');

                if( res.data.status !== true) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                commit('setMissingItems', res.data.data);
                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async scanItem({ commit, state, dispatch }, barcode, bin = '' ) {
            try {
                if( _st.isNUE( barcode ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid barcode is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/check-in', { 
                    barcode,
                    bin,
                    stationId: state.stationId
                });

                if( res.data.status !== true) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                commit( 'setItem', res.data.data );

                // if the item is a shoe, call the ticket generation
                if( res.data.data.product.categoryID == 'SH' )
                    dispatch('printLabel');

                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async registerLostItem({ commit, state }, barcode ) {
            try {
                if( _st.isNUE( barcode ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid item is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/to-lost', { 
                    barcode: barcode,
                    stationId: state.stationId
                });

                if( res.data.status !== true) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                let missingItems = state.missingItems;
                let ix = missingItems.findIndex(i => i.barcode == barcode);
                if( ix != -1 )
                    missingItems.splice(ix, 1);

                commit( 'setMissingItems', missingItems );
                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async registerDamageItem({ commit, state }) {
            try {
                if( _st.isNUE( state.item ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid item is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/to-damaged', { 
                    barcode: state.item.barcode,
                    stationId: state.stationId
                });

                if( res.data.status !== true) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                commit( 'setItem', res.data.data );
                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async force({ commit, state }, { addTurn = false, bin = '' }) {
            try {
                if( _st.isNUE( state.item ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid item is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/force-check-in', { 
                    barcode: state.item.barcode,
                    bin,
                    stationId: state.stationId,
                    addTurn
                });

                if( res.data.status !== true) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                commit( 'setItem', res.data.data );
                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async printLabel({ state }) {
            try {
                let api = new API();
                let res = await api.post('/print-shoes-label', {
                    itemId  : state.item.id
                }, {
                    responseType: 'blob'
                });

                if( res.data?.status === false ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                // const url = window.URL.createObjectURL(new Blob([res.data]));
                // window.open( url, '_blank' );
                const file = new Blob([res.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL( file );
                document.getElementById('printFrame').src = url;

                // this is a workaround for chrome that 
                // doesn't resprect the PDF automatic printing
                let isFirefox = typeof InstallTrigger !== 'undefined';
                if( !isFirefox )
                    setTimeout( () => window.frames['printFrame'].print(), 100 );

                return Promise.resolve( res.data.data );
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
    },
    getters: {
        item        : (state) => state.item,
        missingItems: (state) => state.missingItems,
    }
}