<template>
    <div>
        <v-card>
            <v-card-title class="title">{{ info ? infoTitle : authTitle }}</v-card-title>
            <v-card-subtitle v-html="info ? infoSubtitle : authSubtitle"></v-card-subtitle>

            <v-card-text>
                <p class="red--text" v-if="errorMessage.length > 0">{{ errorMessage }}</p>
                <v-text-field v-model="code" placeholder="Security Code" type="password" clearable :error-messages="codeError" 
                    outlined single-line @keydown.enter="info ? getInfo() : login()" ref="securityCode" 
                    @blur="onBlur"></v-text-field>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text class="text-none" @click="$emit('close')" v-if="closable">Close</v-btn>
                <v-btn depressed class="text-none" @click="info ? getInfo() : login()" :loading="loading">{{ info ? 'Validate' : 'Login' }}</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        auth            : { type: Boolean, default: true },     // create a jwt token when user submit the form
        info            : { type: Boolean, default: false },    // get user information
        authTitle       : { type: String, default: 'Store Login' },
        authSubtitle    : { type: String, default: 'Enter your security code to access the kiosk' },
        infoTitle       : { type: String, default: 'Validate Employee' },
        infoSubtitle    : { type: String, default: 'Enter your security code' },
        closable        : { type: Boolean, default: false },
    },
    data() {
        return {
            code: '',
            codeError: '',
            loading: false,
            errorMessage: '',
        }
    },
    methods: {
        ...mapActions({
            signin      : 'auth/login',
            authAction  : 'auth/authAction'
        }),
        async login() {
            this.codeError = '';
            this.errorMessage = '';
            if( this.code == null || this.code.length == 0 ) {
                this.codeError = 'required';
                return;
            }

            this.loading = true;
            let res = await this.signin( this.code );
            this.loading = false;


            if( !res.data.status && res.data.error ) {
                this.mxShowMessage( 'ERROR: ' + res.data.message, 'error' );
                return false;
            }

            if( !res.data.status && !res.data.error) {
                this.errorMessage = res.data.message;
                this.code = '';
                return;
            }

            this.$emit('close');
            this.code = '';
        },
        async getInfo() {
            this.codeError = '';
            this.errorMessage = '';
            if( this.code == null || this.code.length == 0 ) {
                this.codeError = 'required';
                return;
            }

            try {
                this.loading = true;
                let res = await this.$http.post('/employee/bycode', { code: this.code });
                this.loading = false;
                this.code = '';

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.$emit('userInfo', res.data.data);
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( error, 'error' );
            }
        },
        focus() {
            if( this.$refs.securityCode == undefined )
                return;
                
            this.$refs.securityCode.focus();
        },
        onBlur() {
            setTimeout(() => {
                this.$refs.securityCode.focus();
            }, 100);
        },
    },
}
</script>

<style>

</style>
