<template>
    <v-card :loading="loading">
        <v-card-title>Order history</v-card-title>
        <v-card-subtitle>View all the actions made to an order</v-card-subtitle>
        <v-card-text>
            <v-text-field v-model="orderNum" placeholder="Order number" flat hide-details solo-inverted 
                v-mask="'###-#########'" ref="orderNum" @keydown.enter="loadHistory"></v-text-field>

            <!-- <v-divider class="my-3" v-show="order != null"></v-divider> -->

            <v-simple-table :headers="headers" :items="items" v-show="order != null" dense fixed-header :height="order == null ? 'auto' : '65vh'" 
                class="mt-3">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Action</th>
                        <th>Details</th>
                        <th>Station</th>
                        <th>Style</th>
                        <th>Size</th>
                        <th>Barcode</th>
                        <th>Employee</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, ix) in items" :key="ix">
                        <td>{{ formatDate( item.eventDate, 'YYYY-MM-DD @ hh:mm a' ) }}</td>
                        <td>{{ item.event }}</td>
                        <td>{{ item.eventDetails }}</td>
                        <td>{{ item.stationName }}</td>
                        <td>{{ item.style }}</td>
                        <td>{{ item.size }}</td>
                        <td>{{ item.barcode }}</td>
                        <td>{{ item.employeeFullName }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="grey darken-4" class="white--text" @click="close">Close</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { API } from '@/inc/api';
import { mask } from 'vue-the-mask';
import moment from 'moment';

export default {
    data: () => ({
        orderNum            : '',
        order               : null,

        loading             : false,

        headers             : [
            { text: 'Date', value: 'eventDate', sortable: true },
            { text: 'Action', value: 'event', sortable: true },
            { text: 'Details', value: 'eventDetails', sortable: true },
            { text: 'Station', value: 'stationName', sortable: true },
            { text: 'Style', value: 'style', sortable: true },
            { text: 'Size', value: 'size', sortable: true },
            { text: 'Barcode', value: 'barcode', sortable: true },
            { text: 'Employee', value: 'employeeFullName', sortable: true },
        ],
        pagination          : {
            sortBy: ['eventDate']
        },
        paginationOptions   : { itemsPerPageOptions: [ 100, 150, 200, -1 ] },
    }),
    computed: {
        items() {
            if( this.order == null )
                return [];

            return this.order.history;
        }
    },
    methods: {
        async loadHistory() {
            try {
                if( this.orderNum == '' ) {
                    this.mxShowMessage('You must enter a valid order number.', 'warning' );
                    return;
                }

                let api = new API();

                this.loading = true;
                let res = await api.get(`/order/history/${this.orderNum}`);
                this.loading = false;

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.orderNum = '';
                this.order = res.data.data;
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage(error, 'error');
            }
        },
        focus() {
            if( this.$refs.orderNum === undefined )
                return;

            this.$refs.orderNum.focus();
        },
        close() {
            this.orderNum = '';
            this.order = null;
            this.$emit('close');
        },
        formatDate(d, f = 'YYYY-MM-DD') {
            return moment(d).format(f);
        }
    },
    directives: { mask }
}
</script>

<style lang="scss" scoped>

</style>