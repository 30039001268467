import { management } from './modules/inventoryManagement';
import { managementQty } from './modules/inventoryManagementQty';
import { create } from './modules/inventoryCreate';
import { verify } from './modules/inventoryVerify';

export const inventory = {
    namespaced: true,
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        management,
        managementQty,
        create,
        verify
    }
}
