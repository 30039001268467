import { API } from '@/inc/api';
import { _st } from '@/softech';
import { bus } from '@/main';

export const spotting = {
    namespaced: true,
    state: {
        orderNum    : '',
        order       : null,

        stationId   : 11,
    },
    mutations: {
        setOrderNum( state, payload ) {
            state.orderNum = payload;
        },
        setOrder( state, payload ) {
            state.order = payload;
        },
    },
    actions: {
        setOrderNum({ commit }, payload ) {
            commit('setOrderNum', payload);
        },
        async loadOrder({ commit, state }) {
            try {
                if( _st.isNUE( state.orderNum ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid order number is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post(`/spotting`, { orderNum: state.orderNum });

                if( !res.data.status ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                res.data.data.customer.rentalMeasurements = res.data.data.measurements;
                commit('setOrder', res.data.data);
                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        clearOrder({ commit }) {
            commit('setOrder', null);
        }
    },
    getters: {
        orderNum    : (state) => state.orderNum,
        order       : (state) => state.order,
    }
}