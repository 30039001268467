<template>
    <div>
        <v-menu v-if="value" offset-y offset-x transition="slide-y-transition" min-width="300" max-width="400">
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                <v-badge :value="unreadNotifications > 0" color="error" overlap :content="unreadNotifications"> 
                    <v-icon>mdi-bell-outline</v-icon>
                </v-badge>
                </v-btn>
            </template>
            <v-list dense>
                <div class="text-center pa-2" v-if="notifications == undefined || notifications.length == 0">
                    <v-icon size="64" color="grey lighten-2">mdi-bell-check-outline</v-icon>
                    <div class="caption mt-3 grey--text">You're up to date</div>
                </div>
                <template v-for="(item, ix) in notifications.slice( 0, 5 )">
                    <v-list-item :key="ix" @click="openNotification( item )">
                        <v-list-item-icon class="mt-4">
                            <v-icon v-if="item.type == 'change'">mdi-swap-horizontal</v-icon>
                            <v-icon v-else-if="item.type == 'message'">mdi-message-text-outline</v-icon>
                            <v-icon v-else-if="item.type == 'cancel'">mdi-cancel</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ item.content.length > 30 ? `${item.content.substring( 0 , 27 )}...` : item.content }}<span class="caption grey--text"> - {{ timeAgoItem( item ) }}</span>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-badge dot color="red" class="mt-2" v-show="!isRead( item )"></v-badge>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </v-list>
            <v-btn depressed tile color="primary" block til v-if="notifications.length > 5" @click="allDialog = true">
                See all
            </v-btn>
        </v-menu>

        <v-dialog v-model="notificationDialog" width="500">
            <v-card v-if="selected != null">
                <v-card-title>{{ selected.title }}</v-card-title>
                <v-card-subtitle>{{ timeAgo }}</v-card-subtitle>
                <v-card-text>
                    {{ selected.content }}
                </v-card-text>
                <v-card-actions v-if="objectType != ''">
                    <v-btn depressed block color="grey darken-4" class="white--text" :to="objectLink" @click="notificationDialog = false">
                        Go to {{ objectType }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="allDialog" width="500" scrollable>
            <v-card max-height="600">
                <v-card-title>Notifications</v-card-title>
                <v-card-subtitle>List of all notification within the last 30 days</v-card-subtitle>
                <v-card-text>
                    <v-list dense two-line>
                        <template v-for="(item, ix) in notifications">
                            <v-list-item :key="ix" @click="openNotification( item )">
                                <v-list-item-icon class="mt-4">
                                    <v-icon v-if="item.type == 'change'">mdi-swap-horizontal</v-icon>
                                    <v-icon v-else-if="item.type == 'message'">mdi-message-text-outline</v-icon>
                                    <v-icon v-else-if="item.type == 'cancel'">mdi-cancel</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ item.content }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <span class="caption grey--text">{{ timeAgoItem( item ) }}</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-badge dot color="red" class="mt-2" v-show="!isRead( item )"></v-badge>
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed color="grey darken-4" class="white--text" @click="allDialog = false">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { _st } from '@/softech';
import moment from 'moment';
import { bus } from '@/main';

// QUIERO HACER UN COMPONENTE QUE RECOJA TODA LA FUNCIONALIDAD DE LAS NOTIFICACIONES
// INCLUYENDO EL MENU, EL DIALOG, LOS REDIRECTS, EL GUARDAR LOS LEIDOS, ETC.

export default {
    props: {
        value   : { type: Boolean, default: false },
    },
    data: () => ({
        notificationIntervalId  : null,
        notificationDialog      : false,
        selected                : null,

        allDialog               : false,
    }),
    computed: {
        ...mapGetters({
            user          : 'auth/user',
            isLoggedIn    : 'auth/isLoggedIn',
            notifications : 'notifications'
        }),
        unreadNotifications() {
            let i = 0;
            this.notifications.forEach(n => i += n.seenByUserId.includes( this.user.id ) ? 0 : 1);
            return i;
        },
        objectType() {
            if( _st.isNUE( this.selected?.obj ) )
                return '';

            if( this.selected.obj[0] == 'o' )
                return 'order';

            return '';
        },
        objectLink() {
            let link = '';
            if( this.objectType == 'order' )
                link = `/station/processing?orderId=${this.selected?.obj.replace('o:', '')}`;

            return link;
        },
        timeAgo() {
            return moment( this.selected?.dateCreated ).fromNow();
        },
    },
    mounted() {
        // create timer to load notifications
        this.loadNotifications();
        this.notificationIntervalId = setInterval(() => {
            this.loadNotifications();
        }, 300000);

        bus.$on('login', () => {
            this.loadNotifications()
            this.notificationIntervalId = setInterval(() => {
                this.loadNotifications();
            }, 300000);
        });

        bus.$on('logout', () => clearInterval( this.notificationIntervalId ) );
    },
    methods: {
        ...mapActions({
            loadNotifications   : 'loadNotifications',
            readNotification    : 'readNotification',
        }),
        timeAgoItem( item ) {
            let ago = moment( item.dateCreated ).fromNow();
            return ago.replace('minutes', 'mins').replace('seconds', 'sec').replace('days', 'days');
        },
        isRead( item ) {
            return item.seenByUserId.includes( this.user.id );
        },
        openNotification( item ) {
            try {
                if( item.obj[0] == 'o' ) {
                    if( this.$route.path == '/station/processing' ) 
                        bus.$emit('loadOrder', item.obj.replace('o:', ''));
                    else 
                        this.$router.push(`/station/processing?orderId=${item.obj.replace('o:', '')}`);

                    this.allDialog = false;
                    return;
                }

                this.selected = item;
                this.notificationDialog = true;
            }
            finally {
                // set notification as read
                if( !this.isRead( item ) ) {
                    this.readNotification( item.id );
                }
            }
        }
    },
    beforeDestroy() {
        clearInterval( this.notificationIntervalId );
    },
}
</script>

<style lang="scss" scoped>

</style>