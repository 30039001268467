<template>
    <v-card :loading="loading">
        <v-card-title>Change order status</v-card-title>
        <v-card-subtitle>Change the current status of any order</v-card-subtitle>
        <v-card-text>
            <v-text-field v-model="orderNum" placeholder="Order number" flat hide-details solo-inverted 
                v-mask="'XXX-#########'" ref="orderNum" @keydown.enter="loadOrder"></v-text-field>

            <v-divider class="my-3" v-show="order != null"></v-divider>

            <v-simple-table v-show="order != null">
                <tbody>
                    <tr>
                        <th>Order num</th>
                        <td>{{ order == null ? '' : order.foreignId }}</td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td v-if="order != null">
                            <v-select v-model="order.statusId" :items="orderStatus" placeholder="Status" flat hide-details solo-inverted dense
                                item-value="id" item-text="name"></v-select>
                        </td>
                        <!-- <td>{{ order == null ? '' : order.status }}</td> -->
                    </tr>
                    <tr>
                        <th>Pickup location</th>
                        <td>{{ order == null ? '' : `[${order.pickupLocation.wintuxID}] ${order.pickupLocation.name}  |  ${order.pickupLocation.phone}` }}</td>
                    </tr>
                    <tr>
                        <th>Book date</th>
                        <td>{{ order == null ? '' : formatDate( order.orderDate, 'YYYY-MM-DD @ hh:mm a' ) }}</td>
                    </tr>
                    <tr>
                        <th>Pickup date</th>
                        <td>{{ order == null ? '' : formatDate( order.wantDate, 'YYYY-MM-DD @ hh:mm a' ) }}</td>
                    </tr>
                    <tr>
                        <th>Use date</th>
                        <td>{{ order == null ? '' : order.useDate }}</td>
                    </tr>
                    <tr>
                        <th>Return date</th>
                        <td>{{ order == null ? '' : order.returnDate }}</td>
                    </tr>
                    <tr>
                        <th>Customer type</th>
                        <td>{{ order == null ? '' : order.eventRole }}</td>
                    </tr>
                    <tr>
                        <th>Event</th>
                        <td>{{ order == null ? '' : ( order.eventId == '0' ? '' : order.eventId ) }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed @click="close">Cancel</v-btn>
            <v-btn depressed color="grey darken-4" class="white--text" :loading="processing" @click="changeStatus"
                :disabled="order == null">Change</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { API } from '@/inc/api';
import moment from 'moment';
import { mask } from 'vue-the-mask';

export default {
    data: () => ({
        orderNum    : '',
        order       : null, 

        loading     : false,
        processing  : false,
    }),
    computed: {
        ...mapGetters({
            orderStatus     : 'orderStatus/items',
        })
    },
    mounted() {
        this.init();
        this.$refs.orderNum.focus();
    },
    methods: {
        ...mapActions({
            loadOrderStatus : 'orderStatus/load',
        }),
        init() {
            this.loadOrderStatus();
        },
        async loadOrder() {
            try {
                if( this.orderNum == '' ) {
                    this.mxShowMessage('You must enter a valid order number.', 'warning' );
                    return;
                }

                let api = new API();

                this.loading = true;
                let res = await api.get(`/order/${this.orderNum}`);
                this.loading = false;

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.orderNum = '';
                this.order = res.data.data;
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage(error, 'error');
            }
        },
        async changeStatus() {
            try {
                if( this.order == null ) {
                    this.mxShowMessage('You must first load an order.', 'warning' );
                    return;
                }

                let api = new API();

                this.processing = true;
                let res = await api.post('change-order-status', {
                    orderId: this.order.id,
                    toStatusId: this.order.statusId
                });
                this.processing = false;

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.orderNum = '';
                this.order = null;
                this.mxShowMessage( 'Order status changed', 'success' );
            }
            catch(error) {
                this.processing = false;
                this.mxShowMessage(error, 'error');
            }
        },
        focus() {
            if( this.$refs.orderNum === undefined )
                return;

            this.$refs.orderNum.focus();
        },
        close() {
            this.orderNum = '';
            this.order = null;
            this.$emit('close');
        },
        formatDate(d, f = 'YYYY-MM-DD') {
            return moment(d).format(f);
        }
    },
    directives: { mask }
}
</script>

<style lang="scss" scoped>

</style>