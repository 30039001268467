import { API } from '@/inc/api';
import { bus } from '@/main';

export const productType = {
    namespaced: true,
    state: {
        items    : []
    },
    mutations: {
        setItems( state, payload ) {
            state.items = payload;
        }
    },
    actions: {
        async load({ commit, state }) {
            try {
                if( state.items.length > 0 )
                    return Promise.resolve();

                let api = new API();
                let res = await api.get('/product-types');

                if( !res.data.status ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                commit('setItems', res.data.data);
                return Promise.resolve();
            }
            catch(error) {
                return Promise.reject(error);
            }
        }
    },
    getters: {
        items           : (state) => state.items
    }
}