<template>
    <v-card :loading="loading">
        <v-card-title>Change item status</v-card-title>
        <v-card-subtitle>Change the current status of any item</v-card-subtitle>
        <v-card-text>
            <v-text-field v-model="barcode" placeholder="Barcode or serial #" flat hide-details solo-inverted 
                v-mask="'############'" ref="barcode" @keydown.enter="loadItem"></v-text-field>

            <v-divider class="my-3" v-show="item != null"></v-divider>

            <v-simple-table v-show="item != null">
                <tbody>
                    <tr>
                        <th>Barcode</th>
                        <td>{{ item == null ? '' : item.barcode }}</td>
                    </tr>
                    <tr>
                        <th>Serial #</th>
                        <td>{{ item == null ? '' : item.id }}</td>
                    </tr>
                    <tr>
                        <th>Style</th>
                        <td>{{ item == null ? '' : item.style }}</td>
                    </tr>
                    <tr>
                        <th>Size</th>
                        <td>{{ item == null ? '' : item.size }}</td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td v-if="item != null">
                            <v-select v-model="item.statusId" :items="itemStatus" placeholder="Status" flat hide-details solo-inverted dense
                                item-value="id" item-text="name">
                                <template v-slot:item="{ item, on, attrs }">
                                    <v-list-item v-on="on" v-bind="attrs">
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-select>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed @click="close">Cancel</v-btn>
            <v-btn depressed color="grey darken-4" class="white--text" :loading="processing" @click="changeStatus"
                :disabled="item == null">Change</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { API } from '@/inc/api';
import moment from 'moment';
import { mask } from 'vue-the-mask';

export default {
    data: () => ({
        barcode     : '',
        item        : null, 

        loading     : false,
        processing  : false,
    }),
    computed: {
        ...mapGetters({
            itemStatus     : 'item/status',
        })
    },
    mounted() {
        this.init();
        this.$refs.barcode.focus();
    },
    methods: {
        ...mapActions({
            loadItemStatus : 'item/loadStatus',
        }),
        init() {
            this.loadItemStatus();
        },
        async loadItem() {
            try {
                if( this.barcode == '' ) {
                    this.mxShowMessage('You must enter a valid barcode or serial number.', 'warning' );
                    return;
                }

                let api = new API();

                this.loading = true;
                let res = await api.get(`/item/${this.barcode}`);
                this.loading = false;

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.barcode = '';
                this.item = res.data.data;
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage(error, 'error');
            }
        },
        async changeStatus() {
            try {
                if( this.barcode == null ) {
                    this.mxShowMessage('You must first load an item.', 'warning' );
                    return;
                }

                let api = new API();

                this.processing = true;
                let res = await api.post('change-item-status', {
                    itemId: this.item.id,
                    toStatusId: this.item.statusId
                });
                this.processing = false;

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.barcode = '';
                this.item = null;
                this.mxShowMessage( 'Item status changed', 'success' );
            }
            catch(error) {
                this.processing = false;
                this.mxShowMessage(error, 'error');
            }
        },
        focus() {
            if( this.$refs.barcode === undefined )
                return;

            this.$refs.barcode.focus();
        },
        close() {
            this.barcode = '';
            this.item = null;
            this.$emit('close');
        },
        formatDate(d, f = 'YYYY-MM-DD') {
            return moment(d).format(f);
        }
    },
    directives: { mask }
}
</script>

<style lang="scss" scoped>

</style>