export const dashboard = {
    namespaced: true,
    state: {
        dashboardId : null,
        extraInfo   : null,
        refreshRate : 15,       // in seconds, times between dashboard refresh
        maxErrors   : 2,        // # of consecutive errors that the system will allow without stopping the auto-refresh feature
    },
    mutations: {
        setDashboard( state, payload ) {
            state.dashboardId = payload;
            state.extraInfo = null;
        },
        setExtraInfo( state, payload ) {
            state.extraInfo = payload;
        },
        setRefreshRate( state, payload ) {
            state.refreshRate = payload;
        }
    },
    actions: {
        setDashboard({ commit }, dashboardId) {
            commit('setDashboard', parseInt( dashboardId ));
        },
        setExtraInfo({ commit }, payload) {
            commit('setExtraInfo', payload);
        },
        setRefreshRate({ commit }, payload) {
            commit('setRefreshRate', payload);
        },
        clear({ commit }) {
            commit('setDashboard', null);
            commit('setExtraInfo', null);
        }
    },
    getters: {
        dashboardId : (state) => state.dashboardId,
        extraInfo   : (state) => state.extraInfo,
        refreshRate : (state) => state.refreshRate * 1000,
        maxErrors   : (state) => state.maxErrors,
    }
}