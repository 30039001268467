import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import { _st } from './softech'

export const bus = new Vue();

Vue.config.productionTip = false;

Vue.mixin({
	methods: {
		can( privilege ) {
			return !_st.isNU( store.getters['auth/user'].privileges[privilege] );
		},
		mxShowMessage(text, color, dialog = false, timeout = 6000) {
			bus.$emit('alert', { text, color, timeout, dialog });
		},
		numbersOnly(e) {
			// Allow: backspace, delete, tab, escape, enter and .
			if (
				[46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
				// Allow: Ctrl/cmd+A
				(e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
				// Allow: Ctrl/cmd+C
				(e.keyCode == 67 && (e.ctrlKey === true || e.metaKey === true)) ||
				// Allow: Ctrl/cmd+X
				(e.keyCode == 88 && (e.ctrlKey === true || e.metaKey === true)) ||
				// Allow: home, end, left, right
				(e.keyCode >= 35 && e.keyCode <= 39)
			) {
				// let it happen, don't do anything
				return;
			}

			// Ensure that it is a number and stop the keypress
			if (
				(e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
				(e.keyCode < 96 || e.keyCode > 105)
			) {
				e.preventDefault();
			}
		},
		formatMoney(v) {
			return _st.isNUE(v) ? _st.toMoney(0) : _st.toMoney(v);
		},
		formatNumber(v, p = 0) {
			return _st.isNUE(v) ? _st.toFixedNumber(0, p) : _st.toFixedNumber(v, p);
		},
		formatPhoneNum(v) {
			return _st.toPhoneNumber(v);
		},
		numberWithCommas(v) {
			return v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
		intOnly(e) {
			// Allow: backspace, delete, tab, escape, enter and .
			if (
				[46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
				// Allow: Ctrl/cmd+A
				(e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
				// Allow: Ctrl/cmd+C
				(e.keyCode == 67 && (e.ctrlKey === true || e.metaKey === true)) ||
				// Allow: Ctrl/cmd+X
				(e.keyCode == 88 && (e.ctrlKey === true || e.metaKey === true)) ||
				// Allow: home, end, left, right
				(e.keyCode >= 35 && e.keyCode <= 39)
			) {
				// let it happen, don't do anything
				return;
			}

			// Ensure that it is a number and stop the keypress
			if (
				(e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
				(e.keyCode < 96 || e.keyCode > 105)
			) {
				e.preventDefault();
			}
		},
		orderStatusColor( status ) {
			switch( status.toLowerCase() ) {
				case 'not fitted':
				case 'new order':
				case 'not printed':
					return 'grey darken-1'

				case 'processing':
				case 'qc processing':
				case 'waiting for final inspection':
				case 'tailoring':
				case 'shipping':
				case 'truck line':
					return 'warning';

				case 'store rack':
					return 'primary';

				case 'out not billed':
				case 'out on rental':
				case 'void':
				case 'inactive':
					return 'teal';

				case 'partial ret.':
				case 'returned':
					return 'green'

				case 'cancelled':
				case 'not picked up':
					return 'red';
			}

			return '';
		},
		playSuccess() {
			let audio = new Audio( require('./assets/sounds/rupee-collect.mp3') );
			audio.play();
		},
		playWarning() {
			let audio = new Audio( require('./assets/sounds/icq.wav') );
			audio.play();
		},
		playError() {
			let audio = new Audio( require('./assets/sounds/error.mp3') );
			audio.play();
		},
		playBeep() {
			let audio = new Audio( require('./assets/sounds/smb_coin.wav') );
			audio.play();
		},
		playBeep2() {
			let audio = new Audio( require('./assets/sounds/beep.mp3') );
			audio.play();
		}
	}
});

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
