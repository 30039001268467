import { API } from '@/inc/api';
import { _st } from '@/softech';
import { bus } from '@/main';

export const inStorage = {
    namespaced: true,
    state: {
        item        : null,

        stationId   : 9,
    },
    mutations: {
        setItem( state, payload ) {
            state.item = payload;
        },
    },
    actions: {
        async scanItem({ commit, state }, barcode ) {
            try {
                if( _st.isNUE( barcode ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid barcode is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/in-storage', { 
                    barcode,
                    stationId: state.stationId
                });

                if( res.data.status !== true) {
                    commit( 'setItem', null );
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                commit( 'setItem', res.data.data );
                return Promise.resolve();
            }
            catch(error) {
                commit( 'setItem', null );
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async setAlteration({ commit, state }, alteration) {
            try {
                if( _st.isNUE( state.item ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid item is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/set-alteration', { 
                    barcode: state.item.barcode,
                    altered: alteration,
                    stationId: state.stationId
                });

                if( res.data.status !== true) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                commit( 'setItem', res.data.data );
                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async printLabel({ state }) {
            try {
                let api = new API();
                let res = await api.post('/print-measurement-label', {
                    itemId  : state.item.id,
                    alt     : state.item.altered
                }, {
                    responseType: 'blob'
                });

                if( res.data?.status === false ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                // const url = window.URL.createObjectURL(new Blob([res.data]));
                // window.open( url, '_blank' );
                const file = new Blob([res.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL( file );
                document.getElementById('printFrame').src = url;

                // this is a workaround for chrome that 
                // doesn't resprect the PDF automatic printing
                let isFirefox = typeof InstallTrigger !== 'undefined';
                if( !isFirefox )
                    setTimeout( () => window.frames['printFrame'].print(), 100 );

                return Promise.resolve( res.data.data );
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
    },
    getters: {
        item        : (state) => state.item,
    }
}