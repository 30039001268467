import { API } from '@/inc/api';
import { _st } from '@/softech';
import { bus } from '@/main';

export const verify = {
    namespaced: true,
    state: {
        items           : [],
        style           : '',
        size            : '',
        type            : '',
    },
    mutations: {
        setItems( state, payload ) {
            state.items = payload;
        },
        setStyle( state, payload ) {
            state.style = payload;
        },
        setSize( state, payload ) {
            state.size = payload;
        },
        setType( state, payload ) {
            state.type = payload;
        },
    },
    actions: {
        clearItems({ commit }) {
            commit('setItems', []);
        },
        setStyle({ commit }, payload ) {
            commit('setStyle', payload);
        },
        setSize({ commit }, payload ) {
            commit('setSize', payload);
        },
        setType({ commit }, payload ) {
            commit('setType', payload);
        },
        async addToInventory({ commit, state }, { barcode, style, size, categoryId, storeId }) {
            try {
                if( _st.isNUE( barcode ) ) {
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/inventory/item', { barcode, style, size, categoryId, storeId });

                if( !res.data.status ) {
                    bus.$emit('alert', {
                        text    : res.data.message,
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                let items = state.items;
                let ix = items.findIndex(i => i.id == res.data.data.id);
                if( ix != -1 ) {
                    items.splice( ix, 1 );
                }

                items.unshift( res.data.data );
                commit('setItems', items);

                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', {
                    text    : error,
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async transferOut(_, { transferOutDate, transferCode }) {
            try {
                if( _st.isNUE( transferOutDate ) ) {
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/inventory/transfer', { 
                    date: transferOutDate,
                    code: transferCode
                 });

                if( !res.data.status ) {
                    bus.$emit('alert', {
                        text    : res.data.message,
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                return Promise.resolve( res.data.data );
            }
            catch(error) {
                bus.$emit('alert', {
                    text    : error,
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        }
    },
    getters: {
        items           : (state) => state.items,
        style           : (state) => state.style,
        size            : (state) => state.size,
        type            : (state) => state.type,
    }
}