import { processing } from './modules/stationProcessing';
import { qc } from './modules/stationQC';
import { tailoring } from './modules/stationTailoring';
import { finalInspection } from './modules/stationFinalInspection';
import { truckLine } from './modules/stationTruckLine';
import { checkIn } from './modules/stationCheckIn';
import { inStorage } from './modules/stationInStorage';
import { waitingfi } from './modules/stationWaitingFinalInspection';
import { spotting } from './modules/stationSpotting';

export const stations = {
    namespaced: true,
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        processing,
        qc,
        tailoring,
        finalInspection,
        truckLine,
        checkIn,
        inStorage,
        waitingfi,
        spotting,
    }
}
