import Vue from 'vue';
import Vuex from 'vuex';
import { API } from '@/inc/api';
import { bus } from '@/main';

import { auth } from './modules/auth';
import { productType } from './modules/productType';
import { pickupLocations } from './modules/pickupLocations';
import { orderStatus } from './modules/orderStatus';
import { item } from './modules/item';
import { transferCode } from './modules/transferCode';
import { stations } from './stations';
import { inventory } from './inventory';
import { dashboard } from './dashboard';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pageTitle     : 'LFA Central',
    stores        : [],
    notifications : [],
  },
  mutations: {
    setPageTitle( state, payload ) {
      state.pageTitle = payload;
    },
    setStores( state, payload ) {
      state.stores = payload;
    },
    setNotifications( state, payload ) {
      state.notifications = payload;
    }
  },
  actions: {
    setPageTitle({ commit }, payload ) {
      commit('setPageTitle', payload);
    },
    async loadStores({ state, commit }) {
      if( state.stores.length > 0 )
        return;
      
      try {
        let api = new API();
        let res = await api.get('/stores');

        if( res.data.status !== true ) {
          bus.$emit('alert', { 
              text    : res.data.message, 
              color   : res.data.error ? 'error' : 'warning'
          });
          return;
        }

        commit('setStores', res.data.data);
      }
      catch(error) {
        bus.$emit('alert', { 
          text    : error, 
          color   : 'error'
        });
      }
    },
    async loadNotifications({ commit }) {
      try {
        let api = new API();
        let res = await api.get('/notifications');

        if( res.data.status !== true ) {
          bus.$emit('alert', { 
              text    : res.data.message, 
              color   : res.data.error ? 'error' : 'warning'
          });
          return;
        }

        commit('setNotifications', res.data.data);
      }
      catch( error ) {
        bus.$emit('alert', {
          text    : error,
          color   : 'error'
        });
      }
    },
    async readNotification({ dispatch }, id) {
      try {
        let api = new API();
        let res = await api.post('/notifications/read', { id });

        if( res.data.status !== true ) {
          bus.$emit('alert', { 
              text    : res.data.message, 
              color   : res.data.error ? 'error' : 'warning'
          });
          return;
        }

        dispatch('loadNotifications');
      }
      catch( error ) {
        bus.$emit('alert', {
          text    : error,
          color   : 'error'
        })
      }
    }
  },
  getters: {
    pageTitle     : (state) => state.pageTitle,
    stores        : (state) => state.stores,
    notifications : (state) => state.notifications,
  },
  modules: {
    auth,
    productType,
    pickupLocations,
    orderStatus,
    item,
    transferCode,
    stations,
    inventory,
    dashboard
  }
})
