import { API } from '@/inc/api';
import { _st } from '@/softech';
import { bus } from '@/main';

export const waitingfi = {
    namespaced: true,
    state: {
        orderNum    : '',
        order       : null,
        barcode     : '',

        stationId   : 10,
    },
    mutations: {
        setOrderNum( state, payload ) {
            state.orderNum = payload;
        },
        setOrder( state, payload ) {
            state.order = payload;
        },
        setBarcode( state, payload ) {
            state.barcode = payload;
        }
    },
    actions: {
        setOrderNum({ commit }, payload ) {
            commit('setOrderNum', payload);
        },
        async loadOrder({ commit, state }, force = false ) {
            try {
                if( _st.isNUE( state.orderNum ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid order number is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/waiting-fi', { orderNum: state.orderNum, stationId: state.stationId, force });

                if( res.data.status !== true ) {
                    // check if the item can be forced
                    if( res.data.canBeForced ?? false === true ) {
                        return Promise.resolve({
                            canBeForced     : res.data.canBeForced,
                            text            : res.data.message
                        });
                    }

                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                res.data.data.customer.rentalMeasurements = res.data.data.measurements;
                commit('setOrder', res.data.data);
                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async assignItem({ commit, state, dispatch }, barcode ) {
            try {
                if( _st.isNUE( barcode ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid barcode is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }
                if( _st.isNUE( state.order?.id ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid order number is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }

                // drop item if it is already in the order
                commit( 'setBarcode', barcode );
                if( state.order.assignedItems.map(i => i.barcode).includes( barcode ) ) {
                    let res = await dispatch('item/drop', { 
                        barcode, 
                        stationId: state.stationId, 
                        orderId: state.order.id 
                    }, { root: true });

                    if( res === barcode ) {
                        let order = state.order;
                        let ix = order.assignedItems.findIndex(i => i.barcode == barcode);
                        order.assignedItems.splice( ix, 1 );
                        commit('setOrder', order);
                        return Promise.resolve(barcode);
                    }

                    return Promise.reject(res);
                }

                let api = new API();
                let res = await api.post('/assign-item', {
                    barcode,
                    stationId   : state.stationId,
                    orderId     : state.order.id,
                });

                if( res.data.status !== true ) {
                    // check if the item can be forced
                    if( res.data.canBeForced ?? false === true ) {
                        return Promise.resolve({
                            canBeForced     : res.data.canBeForced,
                            forceReason     : res.data.forceReason,
                            text            : res.data.message
                        });
                    }

                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                let order = state.order;
                if( _st.isNUE( order?.assignedItems ) )
                    order.assignedItems = [];

                order.assignedItems = res.data.data;

                commit('setOrder', order);
                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async forceItem({ commit, state }, forceReasons = [] ) {
            try {
                if( _st.isNUE( state.barcode ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid barcode is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }
                if( _st.isNUE( state.order?.id ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid order number is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/assign-item', {
                    barcode     : state.barcode,
                    stationId   : state.stationId,
                    orderId     : state.order.id,
                    forceReasons
                });

                if( res.data.status !== true ) {
                    // check if the item can be forced
                    if( res.data.canBeForced ?? false === true ) {
                        return Promise.resolve({
                            canBeForced     : res.data.canBeForced,
                            forceReason     : res.data.forceReason,
                            text            : res.data.message
                        });
                    }

                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                let order = state.order;
                if( _st.isNUE( order?.assignedItems ) )
                    order.assignedItems = [];

                order.assignedItems = res.data.data;

                commit('setOrder', order);
                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        clearOrder({ commit }) {
            commit('setOrder', null);
        }
    },
    getters: {
        orderNum    : (state) => state.orderNum,
        order       : (state) => state.order,
    }
}