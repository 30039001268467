import { API } from '@/inc/api';
import { _st } from '@/softech';
import { bus } from '@/main';

export const management = {
    namespaced: true,
    state: {
        items           : [],
        styleFilter     : '',
        sizeFilter      : '',
        barcodeFilter   : '',
        serialFilter    : '',
        typeFilter      : '',
    },
    mutations: {
        setItems( state, payload ) {
            state.items = payload;
        },
        setStyleFilter( state, payload ) {
            state.styleFilter = payload;
        },
        setSizeFilter( state, payload ) {
            state.sizeFilter = payload;
        },
        setBarcodeFilter( state, payload ) {
            state.barcodeFilter = payload;
        },
        setSerialFilter( state, payload ) {
            state.serialFilter = payload;
        },
        setTypeFilter( state, payload ) {
            state.typeFilter = payload;
        },
    },
    actions: {
        setStyleFilter({ commit }, payload ) {
            commit('setStyleFilter', payload);
        },
        setSizeFilter({ commit }, payload ) {
            commit('setSizeFilter', payload);
        },
        setBarcodeFilter({ commit }, payload ) {
            commit('setBarcodeFilter', payload);
        },
        setSerialFilter({ commit }, payload ) {
            commit('setSerialFilter', payload);
        },
        setTypeFilter({ commit }, payload ) {
            commit('setTypeFilter', payload);
        },
        async loadItems({ commit, state }) {
            try {
                if( _st.isNUE( state.styleFilter ) && _st.isNUE( state.sizeFilter ) && _st.isNUE( state.barcodeFilter )
                    && _st.isNUE( state.serialFilter ) && _st.isNUE( state.typeFilter ) ) {
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/items', {
                    style       : _st.isNUE( state.styleFilter ) ? null : state.styleFilter, 
                    size        : _st.isNUE( state.sizeFilter ) ? null : state.sizeFilter,
                    barcode     : _st.isNUE( state.barcodeFilter ) ? null : state.barcodeFilter,
                    serial      : _st.isNUE( state.serialFilter ) ? null : state.serialFilter,
                    type        : _st.isNUE( state.typeFilter ) ? null : state.typeFilter,
                    order       : null,
                    deleted     : true
                });

                if( !res.data.status ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                commit('setItems', res.data.data);
                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async updateItem({ commit, state }, { itemId, barcode, style, size }) {
            try {
                if( _st.isNUE( itemId ) || ( _st.isNUE( barcode ) && _st.isNUE( style ) && _st.isNUE( size ) ) ) {
                    return Promise.reject( false );
                }
                else if( !_st.isNUE( barcode ) && barcode.length !== 8 ) {
                    bus.$emit('alert', {
                        text    : 'Barcode must be 8 characters long',
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.put('/item', { itemId, barcode, style, size });

                if( !res.data.status ) {
                    bus.$emit('alert', {
                        text    : res.data.message,
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                let items = state.items;
                let ix = items.findIndex(i => i.id == itemId);
                if( ix != -1 ) {
                    let item = items[ix];
                    if( !_st.isNUE( barcode ) )
                        item.barcode = barcode;
                    if( !_st.isNUE( style ) )
                        item.style = style;
                    if( !_st.isNUE( size ) )
                        item.size = size;

                    items.splice( ix, 1 );
                    items.push( item );
                    commit('setItems', items);
                }

                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', {
                    text    : error,
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async updateSerial({ commit, state }, { itemId, serial }) {
            try {
                if( _st.isNUE( itemId ) || _st.isNUE( serial ) ) {
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.put('/item/serial', { itemId, serial });

                if( res.data.status !== true ) {
                    bus.$emit('alert', {
                        text    : res.data.message,
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                let items = state.items;
                let ix = items.findIndex(i => i.id == itemId);
                if( ix != -1 ) {
                    let item = items[ix];
                    item.id = serial;

                    items.splice( ix, 1 );
                    items.push( item );
                    commit('setItems', items);
                }

                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', {
                    text    : error,
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async deleteItem({ commit, state }, itemId) {
            try {
                if( _st.isNUE( itemId ) ) {
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/item/delete', { itemId });

                if( !res.data.status ) {
                    bus.$emit('alert', {
                        text    : res.data.message,
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                let items = state.items;
                let ix = items.findIndex(i => i.id == itemId);
                if( ix != -1 ) {
                    items[ix].isDeleted = true;
                    commit('setItems', items);
                }

                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', {
                    text    : error,
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async recoverItem({ commit, state }, itemId) {
            try {
                if( _st.isNUE( itemId ) ) {
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/item/recover', { itemId });

                if( !res.data.status ) {
                    bus.$emit('alert', {
                        text    : res.data.message,
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                let items = state.items;
                let ix = items.findIndex(i => i.id == itemId);
                if( ix != -1 ) {
                    items[ix].isDeleted = false;
                    commit('setItems', items);
                }

                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', {
                    text    : error,
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async transferItem({ commit, state, rootGetters }, { itemId, locationId, code }) {
            try {
                if( _st.isNUE( itemId ) || _st.isNUE( code ) ) {
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/item/transfer', { itemId, locationId, codeId: code.id });

                if( !res.data.status ) {
                    bus.$emit('alert', {
                        text    : res.data.message,
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                let items = state.items;
                let ix = items.findIndex(i => i.id == itemId);
                if( ix != -1 ) {
                    items[ix].transferCodeId = code.id;
                    items[ix].transferCode = code;
                    items[ix].transferredOut = code.transferOut;
                    items[ix].defaultLocationId = locationId;
                    items[ix].defaultLocation = rootGetters.stores.find(i => i.id == locationId).name;
                    commit('setItems', items);
                }

                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', {
                    text    : error,
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async bulkTransfer(_, { barcodes, locationId, code }) {
            try {
                if( _st.isNUE( barcodes ) || _st.isNUE( locationId ) || _st.isNUE( code ) ) {
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/item/transfer/bulk', { barcodes, locationId, codeId: code.id });

                if( !res.data.status ) {
                    bus.$emit('alert', {
                        text    : res.data.message,
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                return Promise.resolve( res.data.errors );
            }
            catch(error) {
                bus.$emit('alert', {
                    text    : error,
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        }
    },
    getters: {
        items           : (state) => state.items,
        styleFilter     : (state) => state.styleFilter,
        sizeFilter      : (state) => state.sizeFilter,
        barcodeFilter   : (state) => state.barcodeFilter,
        serialFilter    : (state) => state.serialFilter,
        typeFilter      : (state) => state.typeFilter,
    }
}