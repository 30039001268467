import { API } from '@/inc/api';
import { _st } from '@/softech';
import { bus } from '@/main';

export const create = {
    namespaced: true,
    state: {
        items           : [],
        supplier        : '',
        po              : '',
        invoiceNum      : '',
        style           : '',
        size            : '',
        alteration      : '',
        mfrsStyle       : '',
    },
    mutations: {
        setItems( state, payload ) {
            state.items = payload;
        },
        pushItem( state, payload ) {
            state.items.unshift( payload );
        },
        setSupplier( state, payload ) {
            state.supplier = payload;
        },
        setPo( state, payload ) {
            state.po = payload;
        },
        setInvoiceNum( state, payload ) {
            state.invoiceNum = payload;
        },
        setStyle( state, payload ) {
            state.style = payload;
        },
        setSize( state, payload ) {
            state.size = payload.toUpperCase();
        },
        setAlteration( state, payload ) {
            state.alteration = payload;
        },
        setMfrsStyle( state, payload ) {
            state.mfrsStyle = payload;
        }
    },
    actions: {
        async createItem({ commit, state }, barcode) {
            try {
                if( _st.isNUE( barcode ) || _st.isNUE( state.style ) ) {
                    bus.$emit('alert', {
                        text    : 'Barcode and style fields cannot be empty',
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }
                else if( barcode.length !== 8 ) {
                    bus.$emit('alert', {
                        text    : 'Barcode must be 8 characters long',
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/item/create', {
                    style       : state.style,
                    size        : state.size,
                    alteration  : state.alteration,
                    barcode     : barcode,
                    supplier    : state.supplier,
                    poNum       : state.po,
                    invoiceNum  : state.invoiceNum,
                    mfrsStyle   : state.mfrsStyle
                });

                if( !res.data.status ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                commit('pushItem', res.data.data);
            }
            catch(error) {
                bus.$emit('alert', {
                    text    : error,
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        setSupplier({ commit }, supplier) {
            commit('setSupplier', supplier);
        },
        setPo({ commit }, po) {
            commit('setPo', po);
        },
        setInvoiceNum({ commit }, invoiceNum) {
            commit('setInvoiceNum', invoiceNum);
        },
        setStyle({ commit }, style) {
            commit('setStyle', style);
        },
        setSize({ commit }, size) {
            commit('setSize', size);
        },
        setAlteration({ commit }, alteration) {
            commit('setAlteration', alteration);
        },
        setMfrsStyle({ commit }, mfrsStyle) {
            commit('setMfrsStyle', mfrsStyle);
        },
        clear({ commit }) {
            commit('setItems', []);
            commit('setSupplier', '');
            commit('setPo', '');
            commit('setInvoiceNum', '');
            commit('setStyle', '');
            commit('setSize', '');
            commit('setAlteration', '');
            commit('setMfrsStyle', '');
        }
    },
    getters: {
        items           : (state) => state.items,
        supplier        : (state) => state.supplier,
        po              : (state) => state.po,
        invoiceNum      : (state) => state.invoiceNum,
        style           : (state) => state.style,
        size            : (state) => state.size,
        alteration      : (state) => state.alteration,
        mfrsStyle       : (state) => state.mfrsStyle
    }
}