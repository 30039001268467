<template>
    <div class="toolbarUserAvatar">
        <v-menu v-model="profileMenu" :close-on-content-click="false" transition="slide-x-transition" 
            v-if="!avatarOnly && !nameOnly" style="left:0;">
            <template v-slot:activator="{ on }">
                <!-- <v-list class="pa-0 transparent" two-line style="width:100%;"> -->
                    <v-list-item avatar class="pr-0" v-on="on" inactive :ripple="false">
                        <v-list-item-avatar @click="avatarClick" v-if="user.avatarSrc != undefined && user.avatarSrc != null">
                            <v-img :src="user.avatarSrc" />
                        </v-list-item-avatar>
                        <v-list-item-avatar @click="avatarClick" v-else>
                            <v-avatar color="grey darken-4" class="white--text" size="40">{{ user.firstName[0] + user.lastName[0] }}</v-avatar>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title @click="nameClick">{{ user.fullName }}</v-list-item-title>
                            <v-list-item-subtitle class="caption">{{ additionalText }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <!-- <v-list-item-action>
                            <v-icon color="grey lighten-1" @click="logout">mdi-exit-to-app</v-icon>
                        </v-list-item-action> -->
                    </v-list-item>
                <!-- </v-list> -->
            </template>

            <v-card top>
                <v-list class="pt-0">
                    <v-list-item class="pt-0">
                        <v-list-item-avatar>
                            <v-avatar color="grey darken-4" class="white--text" size="40">{{ user.firstName[0] + user.lastName[0] }}</v-avatar>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>{{ user.firstName }} {{ user.lastName }}</v-list-item-title>
                            <v-list-item-subtitle>{{ ( can( 'superAdmin' ) ? 'Super ' : '' ) + 'Administrator' }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-btn icon @click="logout">
                                <v-icon>mdi-logout-variant</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>

                <!-- <v-divider></v-divider>

                <v-subheader>Change password</v-subheader>
                <change-password dense ref="pwd" @login="changeUserPassword"></change-password>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn depressed color="warning" class="white--text" @click="changeUserPassword" :loading="changingPwd">Change</v-btn>
                </v-card-actions> -->
            </v-card>
        </v-menu>

        <v-avatar size="40px" v-if="avatarOnly">
            <v-img :src="user.avatarSrc" alt="Avatar" />
        </v-avatar>

        <p class="subheading" v-if="nameOnly">{{ user.fullName }}</p>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
// import ChangePassword from '@/components/ChangePassword.vue';

export default {
    props: {
        user: {
            type: Object,
            default: null,
            required: true
        },
        additionalText: {
            type: String,
            default: '',
            required: false
        },
        avatarOnly: {
            type: Boolean,
            default: false
        },
        nameOnly: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        profileMenu: false,
        changingPwd: false,
    }),
    methods: {
        ...mapActions({
            changePassword  : 'auth/changePassword'
        }),
        avatarClick() {
            this.$emit('avatarClick');
        },
        nameClick() {
            this.$emit('nameClick');
        },
        logout() {
            this.$emit('logout');
        },
        async changeUserPassword() {
            if( !this.$refs.pwd.isFormValid() )
                return;

            try {
                this.changingPwd = true;
                let res = await this.changePassword({
                    oldPassword: this.$refs.pwd.oldPassword,
                    newPassword: this.$refs.pwd.newPassword
                });
                this.changingPwd = false;

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.$refs.pwd.clear();
                this.profileMenu = false;

                // show confirmation message
                this.mxShowMessage( 'Password changed', 'success' );
            }
            catch(error) {
                this.changingPwd = false;
                this.mxShowMessage(error, 'error');
            }
        }
    },
    components: {
        // ChangePassword
    }
}
</script>

<style lang="scss" scoped>
.toolbarUserAvatar {
    margin-left: -25px !important;
}
.v-menu__content {
    left: 0 !important;
}
</style>