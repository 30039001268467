import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';
import { _st } from '@/softech';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Unauthenticated',
    meta: { requiresAuth: false, privileges: [] },
    component: () => import('@/views/401.vue')
  },{
    path: '/404',
    name: 'Not Found',
    meta: { requiresAuth: false, privileges: [] },
    component: () => import('@/views/404.vue')
  },{
    path: '/dashboard',
    name: 'Dashboard',
    meta: { requiresAuth: true, privileges: ['central', 'dashboards'] },
    component: () => import('@/views/Dashboard.vue')
  },{
    path: '/rpt',
    name: 'Report',
    meta: { requiresAuth: true, privileges: ['central', 'dashboards'] },
    component: () => import('@/views/Report.vue')
  },

  // Stations
  {
    path: '/station/processing',
    name: 'Processing',
    meta: { requiresAuth: true, privileges: ['central', 'stationProcessing'] },
    component: () => import('@/views/Processing.vue')
  },{
    path: '/station/qc',
    name: 'QC Processing',
    meta: { requiresAuth: true, privileges: ['central', 'stationQC'] },
    component: () => import('@/views/QC.vue')
  },{
    path: '/station/tailoring',
    name: 'Tailoring',
    meta: { requiresAuth: true, privileges: ['central', 'stationTailoring'] },
    component: () => import('@/views/Tailoring.vue')
  },{
    path: '/station/waiting-fi',
    name: 'Waiting for Final Inspection',
    meta: { requiresAuth: true, privileges: ['central', 'stationWaitingFI'] },
    component: () => import('@/views/WaitingFinalInspection.vue')
  },{
    path: '/station/final-inspection',
    name: 'Final Inspection',
    meta: { requiresAuth: true, privileges: ['central', 'stationFinalInspection'] },
    component: () => import('@/views/FinalInspection.vue')
  },{
    path: '/station/truck-line',
    name: 'Truck Line',
    meta: { requiresAuth: true, privileges: ['central', 'stationTruckLine'] },
    component: () => import('@/views/TruckLine.vue')
  },{
    path: '/station/check-in',
    name: 'Check In',
    meta: { requiresAuth: true, privileges: ['central', 'stationCheckIn'] },
    component: () => import('@/views/CheckIn.vue')
  },{
    path: '/station/storage',
    name: 'In Storage',
    meta: { requiresAuth: true, privileges: ['central', 'stationInStorage'] },
    component: () => import('@/views/InStorage.vue')
  },{
    path: '/station/spotting',
    name: 'spotting',
    meta: { requiresAuth: true, privileges: ['central', 'stationSpotting'] },
    component: () => import('@/views/Spotting.vue')
  },

  // Inventory management routes
  {
    path: '/inventory/create',
    name: 'Create item',
    meta: { requiresAuth: true, privileges: ['central', 'createItem'] },
    component: () => import('@/views/inventory/CreateItems.vue')
  },{
    path: '/inventory/manage',
    name: 'Manage Inventory',
    meta: { requiresAuth: true, privileges: ['central', 'manageInventory'] },
    component: () => import('@/views/inventory/ManageInventory.vue')
  },{
    path: '/inventory/qty/manage',
    name: 'Manage Inventory Qty',
    meta: { requiresAuth: true, privileges: ['central', 'manageInventory'] },
    component: () => import('@/views/inventory/ManageInventoryQty.vue')
  },{
    path: '/inventory/verify',
    name: 'Verify Inventory',
    meta: { requiresAuth: true, privileges: ['central', 'verifyInventory'] },
    component: () => import('@/views/inventory/VerifyInventory.vue')
  },

  // default route
  {
    path: '*',
    redirect: '/404'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  await store.dispatch('auth/authAction');

	const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const privileges = to.matched[0].meta?.privileges??[];
	const isLoggedIn = store.getters['auth/isLoggedIn'];

	if(requiresAuth && !isLoggedIn) {
		next('/');
	} 
  else {

    const user = store.getters['auth/user'];
    
    if( privileges !== undefined && privileges !== null && privileges.length > 0 ) {
      let hasAccess = true;
      for( let p of privileges ) {
        if( _st.isNU( user.privileges[p] ) 
            && _st.isNU( user.privileges['centralAdmin'] )
            && _st.isNU( user.privileges['superAdmin'] ) ) {
          next('/');
          hasAccess = false;
          break;
        }
      }

      if( hasAccess )
        next();
    }
    else {
      next();
    }

	}
})

router.afterEach((to) => {
	Vue.nextTick(() => { store.dispatch('setPageTitle', to.name??'LFA Central'); document.title = to.name ? `LFA Central | ${to.name}` : 'LFA Central'; });
});

export default router
