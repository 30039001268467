import { API } from '@/inc/api';
import { _st } from '@/softech';
import { bus } from '@/main';

export const tailoring = {
    namespaced: true,
    state: {
        orderNum    : '',
        order       : null,

        stationId   : 3,
    },
    mutations: {
        setOrderNum( state, payload ) {
            state.orderNum = payload;
        },
        setOrder( state, payload ) {
            state.order = payload;
        }
    },
    actions: {
        setOrderNum({ commit }, payload ) {
            commit('setOrderNum', payload);
        },
        async loadOrder({ commit, state }, force = false) {
            try {
                if( _st.isNUE( state.orderNum ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid order number is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/tailoring', { orderNum: state.orderNum, force });

                if( res.data.status !== true ) {
                    // check if the item can be forced
                    if( res.data.canBeForced ?? false === true ) {
                        return Promise.resolve({
                            canBeForced     : res.data.canBeForced,
                            text            : res.data.message
                        });
                    }

                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                res.data.data.customer.rentalMeasurements = res.data.data.measurements;
                commit('setOrder', res.data.data);
                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async registerAlteration({ commit, state }, { itemId, alteration }) {
            try {
                if( _st.isNUE( itemId ) ) {
                    bus.$emit('alert', { 
                        text    : 'You must select an item to be altered', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }
                if( _st.isNUE( alteration ) || !_st.isNumeric( alteration ) ) {
                    bus.$emit('alert', { 
                        text    : 'Alteration must have a valid value', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/tailoring/alt', { 
                    itemId: itemId, 
                    alt: alteration, 
                    stationId: state.stationId 
                });

                if( !res.data.status ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                let order = Object.assign({}, state.order);
                let ix = order.assignedItems.findIndex(i => i.id == itemId);
                order.assignedItems[ix].altered = res.data.data.altered;

                commit('setOrder', order);
                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        clearOrder({ commit }) {
            commit('setOrder', null);
        }
    },
    getters: {
        orderNum    : (state) => state.orderNum,
        order       : (state) => state.order,
    }
}