<template>
  <div id="app" v-cloak>
    <v-app>
      <v-navigation-drawer :clipped="$vuetify.breakpoint.lgAndUp" v-model="drawer" fixed :app="isLoggedIn" v-show="isLoggedIn">
        <v-toolbar flat class="transparent">
          <user-avatar v-if="isLoggedIn && user != null" :user="user" :additional-text="user.department + ': ' + user.position" 
            @logout="showLogoutWarning = true"></user-avatar>

          <div class="text-center stage" style="width:100%;" v-else>
            <v-btn @click="$router.push('/')" block color="error" class="bounce elevation-0">Login</v-btn>
          </div>
        </v-toolbar>
        <v-list>
          <v-divider></v-divider>
          <template v-for="item in items">
            <v-row v-if="item.heading" :key="'H' + item.heading" row align-center>
              <v-col cols="12">
                <v-subheader v-if="item.heading">
                  {{ item.heading }}
                </v-subheader>
              </v-col>
            </v-row>

            <v-list-group v-else-if="item.children" :value="item.model" :key="item.text" :prepend-icon="item.icon" no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item v-for="(child, i) in item.children" :key="i" :to="child.to" link>
                <v-list-item-title v-text="child.text"></v-list-item-title>
                <v-list-item-icon>
                  <v-icon v-text="child.icon"></v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>

            <v-list-item v-else :key="item.text" :to="item.to" link>
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </template>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" color="grey darken-4" dark :app="isLoggedIn" fixed v-show="isLoggedIn">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="ml-0"></v-app-bar-nav-icon>
        <v-toolbar-title class="text-left" style="width:100%">
          {{ pageTitle }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <notification :value="toolbarMenu.length > 0" />

        <v-divider dark vertical inset v-if="toolbarMenu.length > 0"></v-divider>

        <v-menu v-if="toolbarMenu.length > 0" offset-y transition="slide-y-transition" min-width="194">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <template v-for="item in toolbarMenu">
              <v-subheader v-if="item.heading" :key="item.id">
                {{ item.text }}
              </v-subheader>
              <v-divider v-else-if="item.divider != undefined && item.divider" :key="item.id"></v-divider>
              <v-list-item :key="item.id" @click="item.action" v-else>
                <v-list-item-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="text-left">{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </v-app-bar>
      <v-main :class="(isLoggedIn ? 'fill-height' : '') + ' grey lighten-4'">
        <router-view :key="$route.path" />
      </v-main>

      <v-dialog v-model="showLogoutWarning" width="600" persistent>
				<v-card>
					<v-card-title>
						<div class="title text-center">
							Are you sure?
						</div>
					</v-card-title>
					<v-card-text>
						<p>
							Are you sure you want to logout? Your current work will be lost
							and cannot be recovered.
						</p>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn depressed text @click="showLogoutWarning = false">Cancel</v-btn>
						<v-btn depressed text @click="logoutUser" color="red">Logout</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

      <v-dialog v-model="orderStatusDialog" width="500">
        <order-status @close="orderStatusDialog = false" ref="orderStatus" />
      </v-dialog>

      <v-dialog v-model="orderHistoryDialog" min-width="500" max-width="900" persistent>
        <order-history @close="orderHistoryDialog = false" ref="orderHistory" />
      </v-dialog>

      <v-dialog v-model="itemStatusDialog" width="500">
        <item-status @close="itemStatusDialog = false" ref="itemStatus" />
      </v-dialog>

      <v-dialog v-model="itemHistoryDialog" min-width="500" max-width="900" persistent>
        <item-history @close="itemHistoryDialog = false" ref="itemHistory" />
      </v-dialog>

      <v-dialog v-model="showLogin" width="500" persistent>
				<employee-auth ref="login" @close="closeLoginForm" auth-title="Central Login" auth-subtitle="Enter your security code to access" />
			</v-dialog>

      <alerts />

      <!-- Print iframe -->
      <iframe name="printFrame" id="printFrame" title="printFrame" style="display:none;"></iframe>
    </v-app>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { API } from '@/inc/api';
import UserAvatar from '@/components/UserAvatar.vue';
import OrderStatus from '@/components/OrderStatus.vue';
import OrderHistory from '@/components/OrderHistory.vue';
import ItemStatus from '@/components/ItemStatus.vue';
import ItemHistory from '@/components/ItemHistory.vue';
import Alerts from '@/components/Alerts.vue';
import EmployeeAuth from '@/components/EmployeeAuth.vue';
import Notification from '@/components/Notification.vue';
import { bus } from '@/main';

export default {
  data() {
    return {
      title: 'LFA Central',
      drawer: null,
      items: [
        { icon: 'mdi-view-dashboard-variant', text: 'Dashboard', to: '/dashboard', model: false },
        { heading: 'Stations' },
        {
          icon: 'mdi-tray-full',
          text: 'Processing',
          to: '/station/processing',
          model: false
        },{
          icon: 'mdi-shield-outline',
          text: 'QC Processing',
          to: '/station/qc',
          model: false,
        },{
          icon: 'mdi-tray-plus',
          text: 'Waiting for FI',
          to: '/station/waiting-fi',
          model: false
        },{
          icon: 'mdi-ruler',
          text: 'Tailoring',
          to: '/station/tailoring',
          model: false
        },{
          icon: 'mdi-shield-plus-outline',
          text: 'Final Inspection',
          to: '/station/final-inspection',
          model: false
        },{
          icon: 'mdi-truck-outline',
          text: 'Truck Line',
          to: '/station/truck-line',
          model: false
        },{
          icon: 'mdi-check-all',
          text: 'Check In',
          to: '/station/check-in',
          model: false,
        },{
          icon: 'mdi-hanger',
          text: 'In Storage',
          to: '/station/storage',
          model: false,
        },{
          icon: 'mdi-spray-bottle',
          text: 'Spotting',
          to: '/station/spotting',
          model: false,
        },
        { heading: 'Inventory' },
        {
          icon: 'mdi-package-variant-closed-check',
          text: 'Manage inventory',
          to: '/inventory/manage',
          model: false
        },{
          icon: 'mdi-list-status',
          text: 'Manage inv. qty',
          to: '/inventory/qty/manage',
          model: false
        },{
          icon: 'mdi-package-variant-closed-plus',
          text: 'Create items',
          to: '/inventory/create',
          model: false
        },{
          icon: 'mdi-store-search-outline',
          text: 'Verify inventory',
          to: '/inventory/verify',
          model: false
        },
      ],
      toolbarMenu: [
        {
          id: 1,
          heading: true,
          text: 'Orders',
        },
        {
          id: 2,
          text: 'Change order status',
          icon: 'mdi-swap-horizontal',
          action: this.openChangeOrderStatusDialog
        },
        {
          id: 3,
          text: 'Order history',
          icon: 'mdi-history',
          action: this.openOrderHistoryDialog
        },
        // { id: 4, divider: true },
        {
          id: 5,
          heading: true,
          text: 'Items',
        },
        {
          id: 6,
          text: 'Change item status',
          icon: 'mdi-swap-horizontal',
          action: this.openChangeItemStatusDialog
        },
        {
          id: 7,
          text: 'Item history',
          icon: 'mdi-history',
          action: this.openItemHistoryDialog
        },
      ],

      finding: false,
      orderStatusDialog: false,
      orderHistoryDialog: false,
      itemStatusDialog: false,
      itemHistoryDialog: false,
      showLogoutWarning: false,
      generatingCodes: false,
      sending: false,

      storeCodes: [],
      selectedCode: null,
      sendToUser: null,

      expressCustomerDialog: false,
      customerError: null,
			customerSaving: false,

      showLogin: false,
    }
  },

  computed: {
    ...mapGetters({
      user          : 'auth/user',
      isLoggedIn    : 'auth/isLoggedIn',
      pageTitle     : 'pageTitle',
    }),
  },

  mounted() {
    bus.$on('login', () => {
      this.init() ;
    });

    this.showLogin = !this.isLoggedIn;

    // set focus to the login field
    setTimeout(() => {
      if( !this.isLoggedIn )
        this.$refs.login.focus();
    }, 200);
  },

  methods: {
    ...mapActions({
      authAction        : 'auth/authAction',
      logout            : 'auth/logout',
    }),
    async init() {
      await this.authAction();
    },
    openChangeOrderStatusDialog() {
      this.orderStatusDialog = true;

      setTimeout(() => {
        this.$refs.orderStatus.focus();
      }, 100);
    },
    openOrderHistoryDialog() {
      this.orderHistoryDialog = true;

      setTimeout(() => {
        this.$refs.orderHistory.focus();
      }, 100);
    },
    openChangeItemStatusDialog() {
      this.itemStatusDialog = true;

      setTimeout(() => {
        this.$refs.itemStatus.focus();
      }, 100);
    },
    openItemHistoryDialog() {
      this.itemHistoryDialog = true;

      setTimeout(() => {
        this.$refs.itemHistory.focus();
      }, 100);
    },
    savingCustomerError(error) {
			this.customerError = error;
		},
    logoutUser() {
      this.showLogoutWarning = false;
      this.logout();
      this.showLogin = true;

      // set focus to the login field
      setTimeout(() => this.$refs.login.focus(), 200);
    },
    async generateCodes() {
      let api = new API();

      try {
        this.generatingCodes = true;
        let res = await api.post('/admin/store/codes', {});
        this.generatingCodes = false;

        if( res.data.status !== true ) {
          this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
          return false;
        }

        this.storeCodes = res.data.data;
      }
      catch(error) {
        this.generatingCodes = false;
        this.mxShowMessage( error, 'error' );
      }
    },
    async closeLoginForm() {
			if( this.isLoggedIn ) {
				this.showLogin = false;
			}

			this.showLogin = false;
		},
    formatTime(d) {
      let date = new Date(d);
      return date.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric'
      });
    },
  },
  components: {
    UserAvatar, Alerts, OrderStatus, OrderHistory, ItemStatus, ItemHistory, EmployeeAuth, Notification
  }
}
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  .v-btn {
    .v-btn__content {
      text-transform: none;
    }
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
