import { API } from '@/inc/api';
import { _st } from '@/softech';
import { bus } from '@/main';

export const managementQty = {
    namespaced: true,
    state: {
        items           : [],
        styleFilter     : '',
        sizeFilter      : '',
        typeFilter      : '',
    },
    mutations: {
        setItems( state, payload ) {
            state.items = payload;
        },
        setStyleFilter( state, payload ) {
            state.styleFilter = payload;
        },
        setSizeFilter( state, payload ) {
            state.sizeFilter = payload;
        },
        setTypeFilter( state, payload ) {
            state.typeFilter = payload;
        },
    },
    actions: {
        setStyleFilter({ commit }, payload ) {
            commit('setStyleFilter', payload);
        },
        setSizeFilter({ commit }, payload ) {
            commit('setSizeFilter', payload);
        },
        setTypeFilter({ commit }, payload ) {
            commit('setTypeFilter', payload);
        },
        async loadItems({ commit, state }) {
            try {
                if( _st.isNUE( state.styleFilter ) && _st.isNUE( state.sizeFilter ) && _st.isNUE( state.barcodeFilter )
                    && _st.isNUE( state.serialFilter ) && _st.isNUE( state.typeFilter ) ) {
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/items/qty', {
                    style       : _st.isNUE( state.styleFilter ) ? null : state.styleFilter, 
                    size        : _st.isNUE( state.sizeFilter ) ? null : state.sizeFilter,
                    type        : _st.isNUE( state.typeFilter ) ? null : state.typeFilter
                });

                if( !res.data.status ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                commit('setItems', res.data.data);
                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async saveItem({ commit, state }, { style, size, qty = 0 }) {
            try {
                if( _st.isNUE( style ) || _st.isNUE( size ) ) {
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/item/qty', { style, size, qty });

                if( !res.data.status ) {
                    bus.$emit('alert', {
                        text    : res.data.message,
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                let items = state.items;
                let ix = items.findIndex(i => i.style == style && i.size == size);
                if( ix != -1 ) {
                    let item = items[ix];
                    item.qty = qty;
                    if( !_st.isNUE( size ) )
                        item.size = size;

                    items.splice( ix, 1 );
                    items.push( item );
                }
                else {
                    items.push( res.data.data );
                }

                commit('setItems', items);
                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', {
                    text    : error,
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async deleteItem({ commit, state }, { productId, size }) {
            try {
                if( _st.isNUE( productId ) || _st.isNUE( size ) ) {
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/item/qty/delete', { productId, size });

                if( !res.data.status ) {
                    bus.$emit('alert', {
                        text    : res.data.message,
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                let items = state.items;
                let ix = items.findIndex(i => i.productId == productId && i.size == size);
                if( ix != -1 ) {
                    items.splice( ix, 1 );
                    commit('setItems', items);
                }

                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', {
                    text    : error,
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
    },
    getters: {
        items           : (state) => state.items,
        styleFilter     : (state) => state.styleFilter,
        sizeFilter      : (state) => state.sizeFilter,
        typeFilter      : (state) => state.typeFilter,
    }
}