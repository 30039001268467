<template>
    <v-card :loading="loading">
        <v-card-title>Item history</v-card-title>
        <v-card-subtitle>View all the actions made to an item</v-card-subtitle>
        <v-card-text>
            <v-text-field v-model="barcode" placeholder="Barcode or serial #" flat hide-details solo-inverted 
                v-mask="'############'" ref="barcode" @keydown.enter="loadHistory"></v-text-field>

            <v-data-table :headers="headers" :items="items" v-show="item != null" dense fixed-header :height="item == null ? 'auto' : '60vh'" 
                :options="pagination" :footer-props="paginationOptions" class="mt-3">
                <template v-slot:[`item.eventDate`]="{ item }">
                    {{ formatDate( item.eventDate, 'YYYY-MM-DD @ hh:mm a') }}
                </template>
                <!-- <thead>
                    <tr>
                        <th>Date</th>
                        <th>Action</th>
                        <th>Details</th>
                        <th>Station</th>
                        <th>Employee</th>
                        <th>Order Num</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, ix) in items" :key="ix">
                        <td>{{ formatDate( item.eventDate, 'YYYY-MM-DD @ hh:mm a' ) }}</td>
                        <td>{{ item.event }}</td>
                        <td>{{ item.eventDetails }}</td>
                        <td>{{ item.stationName }}</td>
                        <td>{{ item.employeeFullName }}</td>
                        <td>{{ item.orderNum }}</td>
                    </tr>
                </tbody> -->
            </v-data-table>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="grey darken-4" class="white--text" @click="close">Close</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { API } from '@/inc/api';
import { mask } from 'vue-the-mask';
import moment from 'moment';

export default {
    data: () => ({
        barcode             : '',
        item                : null,

        loading             : false,

        headers             : [
            { text: 'Date', value: 'eventDate', sortable: true },
            { text: 'Action', value: 'event', sortable: true },
            { text: 'Details', value: 'eventDetails', sortable: true },
            { text: 'Station', value: 'stationName', sortable: true },
            { text: 'Employee', value: 'employeeFullName', sortable: true },
            { text: 'Order', value: 'orderNum', sortable: true },
        ],
        pagination          : {
            sortBy: ['eventDate'],
            sortDesc: [true],
        },
        paginationOptions   : { itemsPerPageOptions: [ 100, 150, 200, -1 ] },
    }),
    computed: {
        items() {
            if( this.item == null )
                return [];

            return this.item.history;
        }
    },
    methods: {
        async loadHistory() {
            try {
                if( this.barcode == '' ) {
                    this.mxShowMessage('You must enter a valid barcode or serial number.', 'warning' );
                    return;
                }

                let api = new API();

                this.loading = true;
                let res = await api.get(`/item/history/${this.barcode}`);
                this.loading = false;

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.barcode = '';
                this.item = res.data.data;
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage(error, 'error');
            }
        },
        close() {
            this.barcode = '';
            this.item = null;
            this.$emit('close');
        },
        focus() {
            if( this.$refs.barcode === undefined )
                return;

            this.$refs.barcode.focus();
        },
        formatDate(d, f = 'YYYY-MM-DD') {
            return moment(d).format(f);
        }
    },
    directives: { mask }
}
</script>

<style lang="scss" scoped>

</style>