import { API } from '@/inc/api';
import { _st } from '@/softech';
import { bus } from '@/main';

export const processing = {
    namespaced: true,
    state: {
        view            : 'i',  // can be i for items, p for print or o for order

        items           : [],
        batches         : [],
        styleFilter     : '',
        sizeFilter      : '',
        barcodeFilter   : '',
        serialFilter    : '',
        typeFilter      : '',
        orderFilter     : '',

        orderNum        : '',
        order           : null,

        printAllDate    : null,
        printFromDate   : null,
        printToDate     : null,
        printOrder      : '',
        reprintOrder    : '',
        reprintBatch    : '',

        stationId       : 1
    },
    mutations: {
        setView( state, payload ) {
            state.view = payload;
        },
        setItems( state, payload ) {
            state.items = payload;
        },
        setBatches( state, payload ) {
            state.batches = payload;
        },
        setStyleFilter( state, payload ) {
            state.styleFilter = payload;
        },
        setSizeFilter( state, payload ) {
            state.sizeFilter = payload;
        },
        setBarcodeFilter( state, payload ) {
            state.barcodeFilter = payload;
        },
        setSerialFilter( state, payload ) {
            state.serialFilter = payload;
        },
        setTypeFilter( state, payload ) {
            state.typeFilter = payload;
        },
        setOrderFilter( state, payload ) {
            state.orderFilter = payload;
        },
        setOrderNum( state, payload ) {
            state.orderNum = payload;
        },
        setOrder( state, payload ) {
            state.order = payload;
        },
        setPrintAllDate( state, payload ) {
            state.printAllDate = payload;
        },
        setPrintFromDate( state, payload ) {
            state.printFromDate = payload;
        },
        setPrintToDate( state, payload ) {
            state.printToDate = payload;
        },
        setPrintOrder( state, payload ) {
            state.printOrder = payload;
        },
        setReprintOrder( state, payload ) {
            state.reprintOrder = payload;
        },
        setReprintBatch( state, payload ) {
            state.reprintBatch = payload;
        }
    },
    actions: {
        setView({ commit }, payload ) {
            commit('setView', payload);
        },
        setStyleFilter({ commit }, payload ) {
            commit('setStyleFilter', payload);
        },
        setSizeFilter({ commit }, payload ) {
            commit('setSizeFilter', payload);
        },
        setBarcodeFilter({ commit }, payload ) {
            commit('setBarcodeFilter', payload);
        },
        setSerialFilter({ commit }, payload ) {
            commit('setSerialFilter', payload);
        },
        setTypeFilter({ commit }, payload ) {
            commit('setTypeFilter', payload);
        },
        setOrderFilter({ commit }, payload ) {
            commit('setOrderFilter', payload);
        },
        setOrderNum({ commit }, payload ) {
            commit('setOrderNum', payload);
        },
        setPrintAllDate({ commit }, payload ) {
            commit('setPrintAllDate', payload);
        },
        setPrintFromDate({ commit }, payload ) {
            commit('setPrintFromDate', payload);
        },
        setPrintToDate({ commit }, payload ) {
            commit('setPrintToDate', payload);
        },
        setPrintOrder({ commit }, payload) {
            commit('setPrintOrder', payload);
        },
        setReprintOrder({ commit }, payload) {
            commit('setReprintOrder', payload);
        },
        setReprintBatch({ commit }, payload) {
            commit('setReprintBatch', payload);
        },
        async loadItems({ commit, state }) {
            try {
                if( _st.isNUE( state.styleFilter ) && _st.isNUE( state.sizeFilter ) && _st.isNUE( state.barcodeFilter )
                    && _st.isNUE( state.serialFilter ) && _st.isNUE( state.typeFilter ) && _st.isNUE( state.orderFilter ) ) {
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/items', {
                    style       : _st.isNUE( state.styleFilter ) ? null : state.styleFilter, 
                    size        : _st.isNUE( state.sizeFilter ) ? null : state.sizeFilter,
                    barcode     : _st.isNUE( state.barcodeFilter ) ? null : state.barcodeFilter,
                    serial      : _st.isNUE( state.serialFilter ) ? null : state.serialFilter,
                    type        : _st.isNUE( state.typeFilter ) ? null : state.typeFilter,
                    order       : _st.isNUE( state.orderFilter ) ? null: state.orderFilter
                });

                if( !res.data.status ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                commit('setItems', res.data.data);
                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async loadBatches({ commit }) {
            try {
                // if( state.batches.length > 0 )
                //     return;

                let api = new API();
                let res = await api.get('/print-batches');

                if( !res.data.status ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                commit('setBatches', res.data.data);
                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async loadOrder({ commit, state }) {
            try {
                if( _st.isNUE( state.orderNum ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid order number is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.get(`/order/${state.orderNum}`);

                if( !res.data.status ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                res.data.data.customer.rentalMeasurements = res.data.data.measurements;
                commit('setOrder', res.data.data);
                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async confirmBatch({ state }, printType = 'range') {
            try {
                let params = {};

                switch( printType ) {
                    case 'through': 
                        params = {
                            type: printType,
                            toDate: state.printAllDate
                        };

                        break;
                    case 'range': 
                        params = {
                            type    : printType,
                            fromDate: state.printFromDate,
                            toDate  : state.printToDate
                        };

                        break;
                    case 'reprintBatch': 
                        params = {
                            type    : printType,
                            batchId : state.reprintBatch
                        };

                        break;
                }

                let api = new API();
                let res = await api.post('/confirm-print', params);

                if( !res.data.status ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                return Promise.resolve( res.data.data );
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async printBatch({ state }, printType = 'range') {
            try {
                let endpoint = '/print-batch';
                let params = {};

                switch( printType ) {
                    case 'through': 
                        params = {
                            type: printType,
                            toDate: state.printAllDate
                        };

                        break;
                    case 'range': 
                        params = {
                            type    : printType,
                            fromDate: state.printFromDate,
                            toDate  : state.printToDate
                        };

                        break;
                    case 'single': 
                        params = {
                            type    : printType,
                            orderNum: state.printOrder,
                        };

                        break;
                    case 'reprintBatch': 
                        endpoint = '/reprint-batch';
                        params = {
                            type    : printType,
                            batchNum: state.reprintBatch
                        };

                        break;
                    case 'reprintOrder': 
                        endpoint = '/reprint-order';
                        params = {
                            type    : printType,
                            orderNum: state.reprintOrder
                        };

                        break;
                }

                let api = new API();
                let res = await api.post(endpoint, params, {
                    responseType: 'blob'
                });

                const isJsonBlob = (data) => data instanceof Blob && data.type === "application/json";
                const responseData = isJsonBlob( res?.data ) ? await ( res?.data )?.text() : res?.data || {};
                res.data = ( typeof responseData === "string" ) ? JSON.parse( responseData ) : responseData;

                if( res.data?.status === false ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                // const url = window.URL.createObjectURL(new Blob([res.data]));
                // window.open( url, '_blank' );
                const file = new Blob([res.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL( file );
                document.getElementById('printFrame').src = url;

                // this is a workaround for chrome that 
                // doesn't resprect the PDF automatic printing
                let isFirefox = typeof InstallTrigger !== 'undefined';
                if( !isFirefox )
                    setTimeout( () => window.frames['printFrame'].print(), 100 );

                return Promise.resolve( res.data.data );
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
    },
    getters: {
        view            : (state) => state.view,

        items           : (state) => state.items,
        batches         : (state) => state.batches,
        styleFilter     : (state) => state.styleFilter,
        sizeFilter      : (state) => state.sizeFilter,
        barcodeFilter   : (state) => state.barcodeFilter,
        serialFilter    : (state) => state.serialFilter,
        typeFilter      : (state) => state.typeFilter,
        orderFilter     : (state) => state.orderFilter,

        orderNum        : (state) => state.orderNum,
        order           : (state) => state.order,

        printAllDate    : (state) => state.printAllDate,
        printFromDate   : (state) => state.printFromDate,
        printToDate     : (state) => state.printToDate,
        printOrder      : (state) => state.printOrder,
        reprintOrder    : (state) => state.reprintOrder,
        reprintBatch    : (state) => state.reprintBatch
    }
}