import { API } from '@/inc/api';
import { _st } from '@/softech';
import { bus } from '@/main';

export const truckLine = {
    namespaced: true,
    state: {
        storeId     : 0,
        store       : null,
        orders      : [],

        shoesOrder  : null,
        shoesBarcode: null,


        stationId   : 5,
    },
    mutations: {
        setStoreId( state, payload ) {
            state.storeId = payload;
        },
        setStore( state, payload ) {
            state.store = payload;
        },
        setOrders( state, payload ) {
            state.orders = payload;
        },
        setShoesInfo( state, { orderId, barcode }) {
            state.shoesOrder = orderId;
            state.shoesBarcode = barcode;
        }
    },
    actions: {
        setStoreId({ commit }, payload ) {
            commit('setStoreId', payload);
        },
        setStore({ commit }, payload ) {
            commit('setStore', payload);
        },
        async loadStore({ commit, state }) {
            try {
                if( _st.isNUE( state.storeId ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid store is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/', { storeId: state.storeId });

                if( res.data.status !== true) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                commit('setStore', res.data.data);
                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async scanOrder({ state }, orderNum ) {
            try {
                if( _st.isNUE( orderNum ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid order number is required', 
                        color   : 'warning'
                    });
                    return Promise.resolve( null );
                }
                if( _st.isNUE( state.store?.id ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid store is required', 
                        color   : 'warning'
                    });
                    return Promise.resolve( null );
                }

                let ix = state.orders.findIndex(o => o == orderNum);
                if( ix != -1 ) {
                    bus.$emit('alert', { 
                        text    : 'Order is already in the list', 
                        color   : 'warning'
                    });
                    return Promise.resolve( null );
                }

                let api = new API();
                let res = await api.get(`/order/${orderNum}`);

                if( res.data.status !== true) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.resolve( null );
                }

                let order = res.data.data;
                if( state.storeId != order.pickupLocationId ) {
                    bus.$emit('alert', { 
                        text    : `The order is not for this store. The correct pickup location is ${order.pickupLocation.name}`, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.resolve( null );
                }

                if( order.statusId != 19 ) {
                    if( order.items.length == 1 && order.items[0].categoryId == 'SH' ) { 
                        console.log( 'Order with only shoes...' );
                    }
                    else {
                        bus.$emit('alert', { 
                            text    : 'The order is not ready to be delivered', 
                            color   : res.data.error ? 'error' : 'warning'
                        });
                        return Promise.resolve( null );
                    }
                }

                let hasShoes = order.items.find(i => i.categoryId == 'SH') !== undefined;
                let isShoesAssigned = order.assignedItems.find(i => i.categoryId == 'SH') !== undefined;
                if( hasShoes && !isShoesAssigned ) {
                    return Promise.resolve( order );
                }

                state.orders.push({ id: order.id, orderNum: order.foreignId });
                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async process({ state }) {
            try {
                if( _st.isNUE( state.store ) ) {
                    bus.$emit('alert', { 
                        text    : 'You must select a delivery location first.', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }
                if( state.orders.length == 0 ) {
                    bus.$emit('alert', { 
                        text    : 'You must scan at least 1 order before processing.', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/truck-line', { orders: _st.extractProp( state.orders, 'id' ) });

                if( !res.data.status ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                if( res.data.errors.length > 0 ) {
                    bus.$emit('alert', {
                        text    : `<ul><li>${res.data.errors.join('</li><li>')}</li></ul>`,
                        color   : 'warning',
                        timeout : 6000,
                        dialog  : true
                    })
                }

                // print manifest
                res = await api.post('truck-line/manifest', {
                    storeId : state.storeId,
                    orders  : _st.extractProp( state.orders, 'id' )
                },{
                    responseType: 'blob'
                });

                if( res.data?.status === false ) {
                    bus.$emit('alert', { 
                        text    : `Generating manifest: ${res.data.message}`,
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }
                else {
                    // const url = window.URL.createObjectURL(new Blob([res.data]));
                    // window.open( url, '_blank' );
                    const file = new Blob([res.data], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL( file );
                    document.getElementById('printFrame').src = url;

                    // this is a workaround for chrome that 
                    // doesn't resprect the PDF automatic printing
                    let isFirefox = typeof InstallTrigger !== 'undefined';
                    if( !isFirefox )
                        setTimeout( () => window.frames['printFrame'].print(), 100 );
                }


                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async assignShoes({ commit, state }, { orderId, foreignId, barcode }) {
            try {
                if( _st.isNUE( barcode ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid barcode is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }
                if( _st.isNUE( orderId ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid order number is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }

                commit('setShoesInfo', { orderId, barcode });

                let api = new API();
                let res = await api.post('/truck-line/assign-shoes', {
                    barcode,
                    orderId,
                    stationId: state.stationId,
                });

                if( res.data.status !== true ) {
                    // check if the item can be forced
                    if( res.data.canBeForced ?? false === true ) {
                        return Promise.resolve({
                            canBeForced     : res.data.canBeForced,
                            text            : res.data.message
                        });
                    }
                    
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                state.orders.push({ id: orderId, orderNum: foreignId });
                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async forceShoes({ commit, state }, { foreignId }) {
            try {
                let api = new API();
                let res = await api.post('/truck-line/force-shoes', {
                    barcode: state.shoesBarcode,
                    orderId: state.shoesOrder,
                    stationId: state.stationId,
                });

                if( res.data.status !== true ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                commit('setShoesInfo', { orderId: null, barcode: null });
                state.orders.push({ id: state.orderId, orderNum: foreignId });
                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        clear({ commit }) {
            commit('setStoreId', 0);
            commit('setStore', null);
            commit('setOrders', []);
        }
    },
    getters: {
        storeId     : (state) => state.storeId,
        store       : (state) => state.store,
        orders      : (state) => state.orders,
    }
}