import { API } from '@/inc/api';
import { _st } from '@/softech';
import { bus } from '@/main';

export const finalInspection = {
    namespaced: true,
    state: {
        orderNum    : '',
        order       : null,
        barcode     : '',

        scannedItems: [],

        stationId   : 4,
    },
    mutations: {
        setOrderNum( state, payload ) {
            state.orderNum = payload;
        },
        setOrder( state, payload ) {
            state.order = payload;
        },
        setBarcode( state, payload ) {
            state.barcode = payload;
        },
        setScannedItems( state, payload ) {
            state.scannedItems = payload;
        }
    },
    actions: {
        setOrderNum({ commit }, payload ) {
            commit('setOrderNum', payload);
        },
        async loadOrder({ commit, state }, force = false ) {
            try {
                if( _st.isNUE( state.orderNum ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid order number is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/final-inspection', { orderNum: state.orderNum, stationId: state.stationId, force });

                if( res.data.status !== true ) {
                    // check if the item can be forced
                    if( res.data.canBeForced ?? false === true ) {
                        return Promise.resolve({
                            canBeForced     : res.data.canBeForced,
                            text            : res.data.message
                        });
                    }

                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                res.data.data.customer.rentalMeasurements = res.data.data.measurements;
                commit('setOrder', res.data.data);
                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async scanItem({ state, commit, dispatch }, barcode ) {
            try {
                if( _st.isNUE( barcode ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid barcode is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }
                if( _st.isNUE( state.order?.id ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid order number is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }

                // drop item if it is already in the order
                commit( 'setBarcode', barcode );
                if( state.scannedItems.map(i => i.barcode).includes( barcode ) ) {
                    let res = await dispatch('item/drop', { 
                        barcode, 
                        stationId: state.stationId, 
                        orderId: state.order.id 
                    }, { root: true });

                    if( res === barcode ) {
                        let order = state.order;
                        let ix = order.assignedItems.findIndex(i => i.barcode == barcode);
                        order.assignedItems.splice( ix, 1 );
                        
                        ix = state.scannedItems.findIndex(i => i.barcode == barcode);
                        state.scannedItems.splice( ix, 1 );

                        commit('setOrder', order);
                        return Promise.resolve();
                    }

                    return Promise.reject(res);
                }

                let api = new API();
                let res = await api.post('/final-inspection/scan', {
                    barcode,
                    stationId   : state.stationId,
                    orderId     : state.order.id,
                });

                if( res.data.status !== true ) {
                    // check if the item can be forced
                    if( res.data.canBeForced ?? false === true ) {
                        return Promise.resolve({
                            canBeForced     : res.data.canBeForced,
                            forceReason     : res.data.forceReason,
                            text            : res.data.message
                        });
                    }

                    if( res.data.error !== true ) {
                        res = await api.post('/assign-item', { // '/item-assign'
                            barcode,
                            stationId   : state.stationId,
                            orderId     : state.order.id,
                            forced      : false
                        });

                        if( res.data.status !== true ) {
                            if( res.data.canBeForced ?? false === true ) {
                                return Promise.resolve({
                                    canBeForced     : res.data.canBeForced,
                                    forceReason     : res.data.forceReason,
                                    text            : res.data.message
                                });
                            }

                            bus.$emit('alert', { 
                                text    : res.data.message, 
                                color   : res.data.error ? 'error' : 'warning'
                            });
                            return Promise.reject( false );
                        }

                        state.scannedItems.push( res.data.data );
                        state.order.assignedItems.push( res.data.data );
                        return Promise.resolve( res.data.data );
                    }

                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                let item = res.data.data;
                let ix = state.scannedItems.findIndex(i => i.productId == item.productId);
                if( ix == -1 ) {
                    state.scannedItems.push( item );
                }
                else {
                    state.scannedItems.splice( ix, 1, item );
                }

                ix = state.order.assignedItems.findIndex(i => i.barcode == barcode);
                state.order.assignedItems[ix] = item;

                return Promise.resolve( item );
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async forceItem({ state }, forceReasons = []) {
            try {
                if( _st.isNUE( state.barcode ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid barcode is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }
                if( _st.isNUE( state.order?.id ) ) {
                    bus.$emit('alert', { 
                        text    : 'A valid order number is required', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/final-inspection/scan', { // '/final-inspection/force'
                    barcode     : state.barcode,
                    stationId   : state.stationId,
                    orderId     : state.order.id,
                    forceReasons
                });

                if( res.data.status !== true ) {
                    if( res.data.canBeForced ?? false === true ) {
                        return Promise.resolve({
                            canBeForced     : res.data.canBeForced,
                            forceReason     : res.data.forceReason,
                            text            : res.data.message
                        });
                    }

                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                let item = res.data.data;
                let ix = state.scannedItems.findIndex(i => i.productId == item.productId); // (i => i.categoryId == item.categoryId)
                if( ix == -1 ) {
                    state.scannedItems.push( item );
                }
                else {
                    state.scannedItems.splice( ix, 1, item );
                }

                if( forceReasons.includes('not_belong') || forceReasons.includes('replace_item') )
                    ix = state.order.assignedItems.findIndex(i => i.categoryId == item.categoryId);
                else
                    ix = state.order.assignedItems.findIndex(i => i.barcode == state.barcode);

                state.order.assignedItems[ix] = item;

                return Promise.resolve( item );
            }
            catch(error) {
                bus.$emit('alert', {
                    text    : error,
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        async process({ state }) {
            try {
                if( _st.isNUE( state.order ) ) {
                    bus.$emit('alert', { 
                        text    : 'You must open an order first', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }

                let needed = state.order.items.filter(i => i.hasBarcode == true && i.categoryId != 'SH');
                if( needed.length != state.scannedItems.length ) {
                    bus.$emit('alert', { 
                        text    : 'There are items that has not been scanned yet', 
                        color   : 'warning'
                    });
                    return Promise.reject( false );
                }

                let api = new API();
                let res = await api.post('/final-inspection/process', { orderId: state.order.id });

                if( !res.data.status ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                return Promise.resolve();
            }
            catch(error) {
                bus.$emit('alert', { 
                    text    : error, 
                    color   : 'error'
                });
                return Promise.reject( false );
            }
        },
        clearOrder({ commit }) {
            commit('setOrder', null);
            commit('setScannedItems', []);
        }
    },
    getters: {
        orderNum    : (state) => state.orderNum,
        order       : (state) => state.order,
        scannedItems: (state) => state.scannedItems,
    }
}